import React, {useEffect} from 'react';
import styled from "styled-components";

import {Container, Row} from "react-bootstrap";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import {Img} from "../Img";
import {SplitText} from 'gsap/SplitText';
import {gsap, TimelineMax} from "gsap";
import {useLocation} from "react-router-dom";
import VideoBanner from "../VideoBanner";

const Banner = ({data}) => {

    const location = useLocation();

    useEffect(() => {


            const animateText = async () => {
                // Hide the text initially
                gsap.set('.gradient', {opacity: 0});

                // Create a TimelineMax instance
                const timeline = new TimelineMax();

                // Add animation to reveal the text
                timeline.to('.gradient', {
                    opacity: 1,
                    duration: 0.5, // Adjust the duration as needed
                    ease: 'power1.out',
                });

                // Add SplitText animation within the timeline
                timeline.add(() => {
                    const split = new SplitText('.gradient', {type: 'chars,words'});

                    gsap.to(split.chars, {
                        y: '-20%',
                        duration: 2,
                        ease: 'sine.inOut',
                        stagger: {
                            each: 0.5,
                            repeat: -1,
                            yoyo: true,
                        },
                    });
                });
                // var tl = gsap.timeline({repeat: -1});
                // tl.to(".banner-gradient", 30, {backgroundPosition: "-960px 0"});
                // Remove the animation when the element is out of view or no longer needed
                // timeline.eventCallback('onComplete', () => {
                //     timeline.kill(); // This may not be necessary depending on the version of TweenMax
                // });
            };

            animateText();

        const ColorText = async () => {
            var tl = gsap.timeline({repeat: -1});
            tl.to(".banner-gradient", 30, {

                backgroundPosition: "-960px 0",
                y: '-20%',
                duration: 2,
                ease: 'sine.inOut',
                stagger: {
                    each: 0.5,
                    repeat: -1,
                    yoyo: true,
                },

            });
            // Remove the animation when the element is out of view or no longer needed
            // timeline.eventCallback('onComplete', () => {
            //     timeline.kill(); // This may not be necessary depending on the version of TweenMax
            // });
        };

        ColorText();
    }, []);


    return (
        <StyledInnerBanner className='banner'>
            <VideoBanner videoSource={data?.images?.list[0]?.full_path}/>
            <Container>
                    <div className={'banner__body'}>
                        <div>
                            <h1 className={`anim-active fade-up gradient`}>{reactHtmlParser(data?.section_data?.subtitle)}</h1>
                            <h1 className={'banner-text banner-gradient'}>{reactHtmlParser(data?.section_data?.short_desc)}</h1>
                        </div>
                    </div>
                    <div className={'banner__ac'}>
                        {window.innerWidth > 767 ?
                            <img data-speed={0.9} src={'images/static/ac-new.png'}/>
                            :
                            <img src={'images/static/ac-mobile-new.png'}/>
                        }
                    </div>
            </Container>

        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  background: transparent !important;
  position: relative;
  padding-top: calc(1080 / 1366 * 100%);
  z-index: 10;
  //background-color: rgba(110, 175, 196, 0.30);
  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.30);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;;
  }

  .global-image {
    overflow: hidden;
  }

  .video-banner {
    video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;
    }
  }

  .container {
    display: flex;
    justify-content: center;
  }

  h1 {
    font-size: 60px;
    line-height: 75px;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;

    span {
      font-weight: 500;
    }
  }

  .banner {
    &__body {


      position: absolute;
      //height: 100%;
      //top: 0;
      left: 0;
      right: 0;
      bottom: 53%;
      top: 47%;
      z-index: 2;

      .banner-text {
        font-weight: 500;
        background-image: url('/images/static/text-bc.png');
        background-attachment: fixed;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }

    }

    &__ac {
      position: absolute;
      bottom: -120px;

      img {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        position: relative;
        z-index: 10;
      }
    }
  }


  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: calc(1000 / 600 * 100%);
  }

  @media (max-width: 767px) {
    //padding-top: 0;
    padding-top: calc(812 / 375 * 100%);

    .banner {
      &__body {
        &__svg {
          right: unset;
          bottom: -200px;
        }
      }

      &__ac {
        bottom: -55px;
      }
    }

    h1 {
      font-size: 42px;
      line-height: 50px;
      font-weight: 300;
      color: #FFFFFF;
      text-align: center;

      span {
        font-weight: 500;
      }
    }

    .container {
      display: flex;
      justify-content: center;
    }


    .title {
      margin-bottom: 40px !important;
    }
  }
`;

export default Banner;
